<template>
	<div
		id="compare-drop-down"
		class="mobile-button-container"
		:class="showCompare ? ' open' : ''"
		v-click-outside="() => (showCompare = false)"
	>
		<div id="selected-div" @click="showCompare = !showCompare">
			<span>{{ props.optionsText }}</span>
			<div class="arrow">
				<svg
					class="w-6 h-6"
					fill="none"
					stroke="currentColor"
					viewBox="0 0 24 24"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
				</svg>
			</div>
		</div>
		<div class="mobile-button-options" v-show="showCompare">
			<div
				class="mobile-button-option"
				v-for="elem in filterOptions"
				:key="elem"
				@click="compareOptionOnClick(elem)"
				:class="[props.selectedCompares.includes(elem) ? ' selected-option' : '', elem?.class]"
			>{{ customName(elem) }}</div>
		</div>
	</div>
	<Search
		v-if="props.searchCompare"
		placeholder="Sök efter aktier"
		targetId="search-compare"
		:searchCompare="true"
		:onClickFunction="compareOptionOnClick"
	></Search>
	<div id="millistream-selector"></div>
</template>

<script>
import { ref, computed, watch } from "vue";
import Search from "@/components/Search.vue";

import vClickOutside from "click-outside-vue3";
export default {
	components: {
		Search,
	},
	props: {
		options: {
			type: Array,
			required: true,
		},
		searchCompare: {
			type: Boolean,
			default: false,
		},
		optionsText: {
			type: String,
			required: true,
		},
		instrument: {
			type: Number,
			required: true,
		},
		addCompare: {
			type: Function,
			required: true
		},
		removeCompare: {
			type: Function,
			required: true
		},
		selectedCompares: {
			type: Array, required: true,
		}
	},
	directives: {
		clickOutside: vClickOutside.directive,
	},
	setup(props, { emit }) {
		const showCompare = ref(false);
		const tabindex = 0;
		const filterOptions = computed(() => {
			return props.options.filter((elem) => elem.insref !== props.instrument);
		});
		const customName = (elem) => {
			switch (elem.insref) {
				case 926:
					return "EUR";
				case 150:
					return "USD";
				case 72825:
					return "GBP";
				case 72829:
					return "CHF";
				case 72828:
					return "NOK";
				case 72826:
					return "DKK";
				case 51623:
					return "CAD";
				case 117438:
					return "AUD";
				case 117083:
					return "JPY";
				default:
					return elem.name;
			}
		}
		const compareOptionOnClick = (option, mode) => {
			if (mode === 'search') {
				if (props.selectedCompares.length == 3  && !props.selectedCompares.includes(undefined)) return;
				let temp = {
					insref: option.insref,
					name: option.name,
					class: getDotClass(),
				}
				if (temp.insref === props.instrument) return;
				props.addCompare(temp);
			}
			else {
				if (option.active === false) {
					if (props.selectedCompares.length == 3 && !props.selectedCompares.includes(undefined)) return;
					else {
						if (option.insref === props.instrument) return;
						option.active = true;
						option.class = getDotClass();
						props.addCompare(option);
					}
				} else {
					option.active = false;
					option.class = null;
					props.removeCompare(option);
				}
			}
		};
		const getDotClass = () => {
			if (props.selectedCompares.length == 0 || props.selectedCompares.filter((elem) => elem?.class == 'compare-1').length === 0) return 'compare-1';
			else if (props.selectedCompares.length == 1 || props.selectedCompares.filter((elem) => elem?.class == 'compare-2').length === 0) return 'compare-2';
			else if (props.selectedCompares.length == 2 || props.selectedCompares.filter((elem) => elem?.class == 'compare-3').length === 0) return 'compare-3';
		}
		return {
			showCompare,
			customName,
			filterOptions,
			props,
			compareOptionOnClick
		};
	},
};
</script>
<style lang="scss">
@import "@/assets/scss/variables.scss";
// .orange-dot {
// 	display: flex;
// 	align-items: center;
// 	gap: 0.5rem;
// 	&::after {
// 		content: "";
// 		border-radius: 50%;
// 		height: 0.5rem;
// 		width: 0.5rem;
// 		background-color: $orange;
// 	}
// }
// .blue-dot {
// 	display: flex;
// 	align-items: center;
// 	gap: 0.5rem;
// 	&::after {
// 		content: "";
// 		border-radius: 50%;
// 		height: 0.5rem;
// 		width: 0.5rem;
// 		background-color: $blue;
// 	}
// }
// .green-dot {
// 	display: flex;
// 	align-items: center;
// 	gap: 0.5rem;
// 	&::after {
// 		content: "";
// 		border-radius: 50%;
// 		height: 0.5rem;
// 		width: 0.5rem;
// 		background-color: $green;
// 	}
// }
	.compare-1 {
		cursor: pointer;
		display: flex;
		align-items: center;
		font-family: Sueca Hd;
		vertical-align: bottom;
		font-style: normal;
		font-weight: 600;
		color: #252d40;
		gap: 0.5rem;

		&::before {
			content: "";
			border-radius: 50%;
			height: 0.5rem;
			width: 0.5rem;
			background-color: $orange;
		}

		&:hover {
			text-decoration: line-through;
			color: rgba(0, 0, 0, 0.5)
		}
	}

	.compare-2 {
		cursor: pointer;
		display: flex;
		align-items: center;
		font-family: Sueca Hd;
		vertical-align: bottom;
		font-style: normal;
		font-weight: 600;
		color: #252d40;
		gap: 0.5rem;

		&::before {
			content: "";
			border-radius: 50%;
			height: 0.5rem;
			width: 0.5rem;
			background-color: $blue;
		}

		&:hover {
			text-decoration: line-through;
			color: rgba(0, 0, 0, 0.5)
		}
	}

	.compare-3 {
		cursor: pointer;
		display: flex;
		align-items: center;
		font-family: Sueca Hd;
		vertical-align: bottom;
		font-style: normal;
		font-weight: 600;
		color: #252d40;
		gap: 0.5rem;

		&::before {
			content: "";
			border-radius: 50%;
			height: 0.5rem;
			width: 0.5rem;
			background-color: $green;
		}

		&:hover {
			text-decoration: line-through;
			color: rgba(0, 0, 0, 0.5)
		}
	}
@media (min-width: 781px) {
	.mobile-button-container#compare-drop-down {
		width: 12rem;
		.mobile-button-options {
			border: 1px solid rgba(0, 0, 0, 0.16);
			width: -webkit-max-content;
			width: -moz-max-content;
			width: max-content;
			min-width: 100%;
			right: 0;
		}
	}
}
@media (max-width: 781px) {
}
</style>
