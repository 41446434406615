<template>
  <div id="desktop-nav">
    <div
      v-for="elem in links"
      :key="elem"
      :class="elem.text === selected ? 'link-active' : ''"
      @click="changePage(elem.link)">
      <span>{{ elem.text }}</span>
    </div>
  </div>
  <div
    id="mobile-nav"
    :class="showOptions ? ' open' : ''"
    v-click-outside="() => (showOptions = false)">
    <div id="selected-div" @click="showOptions = !showOptions">
      <span>{{ selected }}</span>
      <div class="arrow">
        <svg
          class="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M19 9l-7 7-7-7" />
        </svg>
      </div>
    </div>
    <div id="mobile-nav-options" v-show="showOptions">
      <div
        v-for="elem in links"
        :key="elem"
        @click="changePage(elem.link)"
        :class="elem.text === selected ? 'link-active' : ''">
        <span>{{ elem.text }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import { toRefs, ref, computed } from "vue"
import vClickOutside from "click-outside-vue3"
export default {
  props: {
    selected: {
      type: String,
    },
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  setup(props) {
    const links = [
      { text: "Översikt", link: "./overview.php" },
      { text: "Index", link: "./indexlist.php" },
      { text: "Aktier", link: "./shares.php" },
      { text: "Fonder", link: "./funds.php" },
      { text: "Valuta", link: "./valuta.php" },
      { text: "Räntor", link: "./interest.php" },
      { text: "Råvaror", link: "./materials.php" },
      { text: "Min lista", link: "./mylist.php" },
    ]
    const showOptions = ref(false)
    // const options = computed(() =>
    // 	links.filter((elem) => elem.text != props.selected)
    // );
    const changePage = (url) => {
      window.location.href = url
    }
    return { showOptions, links, changePage }
  },
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

#mobile-nav {
  margin: 1rem;
  // width: 100%;
  display: none;
  position: relative;
  font-family: SuecaSans;

  #mobile-nav-options {
    position: absolute;
    width: 100%;
    background-color: $page-color;
    display: flex;
    flex-direction: column;
    border-radius: 0 0 4px 4px;
    border: $navbar-border;
    border-top: none;
    box-shadow: 0px 8px 12px rgb(0 0 0 / 6%);
    z-index: 10000;
    div {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      padding: 0.5rem 1rem;
      span {
        font-weight: $font-weight-600;
        line-height: 18px;
        font-size: 15px;
        text-decoration: none;
        color: $excerpt;
      }
      &.link-active {
        span {
          display: inline-block;
          position: relative;
          &:after {
            content: "";
            position: absolute;
            width: 100%;
            transform: scaleX(1);
            height: 1px;
            bottom: 0;
            left: 0;
            background-color: $excerpt;
            transform-origin: bottom right;
            transition: transform 0.3s ease-in-out;
          }
        }
      }
    }
  }
  #selected-div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-style: normal;
    line-height: 18px;
    font-size: 15px;
    border: $navbar-border;
    border-radius: $navbar-border-radius;
    color: $excerpt;
    padding: 8px 16px;
    font-weight: $font-weight-600;
    &.link-active {
      background-color: $purple1;
      color: $white;
    }
  }

  .arrow {
    width: 1rem;
    height: 1rem;
    color: $excerpt;
  }
  &.open {
    #selected-div {
      border-bottom: none;
      border-radius: 4px 4px 0 0;
    }
  }
}

#desktop-nav {
  font-family: SuecaSans;
  width: 100%;
  display: none;
  margin: 24px auto;
  height: 36px;
  max-width: 1250px;
  div {
    display: grid;
    place-items: center;
    flex: 1;
    cursor: pointer;

    &.link-active {
      border-radius: 2px;
      background-color: $purple1;
      span {
        color: $white;
      }
    }
    &:not(.link-active) {
      border-top: $navbar-border;
      border-bottom: $navbar-border;
      &:first-child {
        border-left: $navbar-border;
        border-radius: 2px 0 0 2px;
      }
      &:last-child {
        border-right: $navbar-border;
        border-radius: 0 2px 2px 0;
      }
      &:hover span:after {
        transform: scaleX(1);
        transform-origin: bottom left;
      }
    }
    span {
      display: inline-block;
      font-style: normal;
      position: relative;
      line-height: 18px;
      text-decoration: none;
      font-size: 15px;
      color: $excerpt;
      font-weight: $font-weight-600;
      &:after {
        content: "";
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 1px;
        bottom: 0;
        left: 0;
        background-color: $excerpt;
        transform-origin: bottom right;
        transition: transform 0.3s ease-in-out;
      }
    }
  }
}
#side-bar a:hover:after {
}

@media (min-width: 781px) {
  #desktop-nav {
    display: flex;
  }
}

@media (max-width: 781px) {
  #mobile-nav {
    display: block;
  }
}
</style>
