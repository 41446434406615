<template>
	<div id="star-div" @click="onClick">
		<img src="@/assets/images/filled_star.svg" v-if="isInMyList" />
		<img src="@/assets/images/empty_star.svg" v-else />
	</div>
</template>


<script>
import { onBeforeMount, ref } from "vue";
import { cookieName, domain } from "@/utilities/cookie.js";
export default {
	components: {},
	props: {
		insref: {
			type: Number,
			required: true,
		},
	},
	setup(props) {
		const isInMyList = ref(null);
		const onClick = () => {
			if (true == milli_widget_is_in_cookie(cookieName, props.insref, domain)) {
				milli_widget_delete_cookie_value(cookieName, props.insref, domain);
				isInMyList.value = false;
			} else {
				milli_widget_append_cookie_value(cookieName, props.insref, domain);
				isInMyList.value = true;
			}
		};
		onBeforeMount(() => {
			if (true == milli_widget_is_in_cookie(cookieName, props.insref, domain))
				isInMyList.value = true;
			else isInMyList.value = false;
		});
		return {
			isInMyList,
			onClick
		};
	},
};
</script>
<style lang="scss">
@import "@/assets/scss/variables.scss";
#star-div {
	position: absolute;
	right: 0.5rem;
	top: 0.5rem;
	cursor: pointer;
}
@media (min-width: 781px) {
}
@media (max-width: 781px) {
}
</style>
