export function getCurrencyCustomNames(type) {
    const allCustomNames = {
        926: "EUR",
        150: "USD",
        72825: "GBP",
        72829: "CHF",
        72828: "NOK",
        72826: "DKK",
        51623: "CAD",
        117438: "AUD",
        117083: "JPY",
        21851: "AUD",
        21859: "BRL",
        21862: "CAD",
        21863: "CHF",
        21865: "CNY",
        21869: "CZK",
        21891: "DKK",
        21909: "EUR",
        21910: "GBP",
        21927: "HKD",
        21928: "HUF",
        21945: "IDR",
        21962: "INR",
        21963: "ISK",
        21964: "JPY",
        21965: "KRW",
        21990: "MAD",
        21991: "MXN",
        22011: "NOK",
        22012: "NZD",
        22014: "PLN",
        22019: "RUB",
        22036: "SAR",
        22074: "SGD",
        22140: "THB",
        22142: "TRY",
        22143: "USD",
        22147: "ZAR",
        3351876: "BTC",
        3351880: "ETH",
        3351882: "LTC",
        3467140: "BCH",
    };
    const spotCustomNames = {
        926: "EUR",
        150: "USD",
        72825: "GBP",
        72829: "CHF",
        72828: "NOK",
        72826: "DKK",
        51623: "CAD",
        117438: "AUD",
        117083: "JPY",
    }
    const fixingCustomNames = {
        21851: "AUD",
        21859: "BRL",
        21862: "CAD",
        21863: "CHF",
        21865: "CNY",
        21869: "CZK",
        21891: "DKK",
        21909: "EUR",
        21910: "GBP",
        21927: "HKD",
        21928: "HUF",
        21945: "IDR",
        21962: "INR",
        21963: "ISK",
        21964: "JPY",
        21965: "KRW",
        21990: "MAD",
        21991: "MXN",
        22011: "NOK",
        22012: "NZD",
        22014: "PLN",
        22019: "RUB",
        22036: "SAR",
        22074: "SGD",
        22140: "THB",
        22142: "TRY",
        22143: "USD",
        22147: "ZAR",
    }
    const cryptoCustomNames = {
        3351876: "BTC",
        3351880: "ETH",
        3351882: "LTC",
        3467140: "BCH",
    }
    if(type === 'all') return allCustomNames;
    if(type === 'spot') return spotCustomNames;
    if(type === 'fixing') return fixingCustomNames;
    if(type === 'crypto') return cryptoCustomNames;
    else return false;
    
}