import { cookieName, domain } from "./cookie.js";

export function createCustomColumn(index) {
	// INDEX ÄR VILKEN COLUMN (SISTA COLUMNEN OFTAST)
	// LIST ÄR VILKEN LISTA DEN SKA LÄGGAS TILL I, TEX aktier eller index
	const column = {
		[0]: {
			element: "span",
			type: null,
			class: "millistream-mylist-icon-add",
			tdclass: 'millistream-mylist-td',
			name: "",
			onclick: function (e) {
				if (
					true ==
					milli_widget_is_in_cookie(
						cookieName,
						e.insref, domain
					)
				) {
					milli_widget_delete_cookie_value(
						cookieName,
						e.insref, domain
					);
					e.event.target.classList.add(
						"millistream-mylist-icon-add"
					);
					e.event.target.classList.remove(
						"millistream-mylist-icon-sub"
					);
				} else {
					milli_widget_append_cookie_value(
						cookieName,
						e.insref, domain
					);
					e.event.target.classList.remove(
						"millistream-mylist-icon-add"
					);
					e.event.target.classList.add(
						"millistream-mylist-icon-sub"
					);
				}
			},
			alternate_class: "millistream-mylist-icon-sub",
			instruments: milli_widget_get_cookie_array(cookieName, domain),
		},
	};
	return column;
}





