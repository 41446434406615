export const sweIndexes = [
		6486,
		6485,
		323658,
		323663,
		323670,
		323691,
		323703,
		323711,
		323722,
		4999813,
		4999815,
		2707,
		68208,
		5088217,
		5088219,
		5088221,
		5090425,

	];
