<template>
    <div
        class="white-box"
        id="history-div"        
    >
        <List v-bind="detailedInstrument"></List>
    </div>
</template>
<script>
import List from "@/components/List.vue";
export default {
    components: {
        List,        
    },
    props: {
        instruments: {
            type: Array,
            required: true,
        },
    },
    setup(props) {
        const detailedInstrument = {
            instrument: props.instruments,
            headerText: "Historisk utveckling",
            fields: [
                "diff1dprc",
                "diff1wprc",
                "diff1mprc",
                "diff3mprc",
                "diffytdprc",
                "diff1yprc",
                "diff5yprc",
                "diff10yprc",
            ],
            targetId: "history-list",
            custom_lang: {
                diff1dprc: "1 dag",
                diff1wprc: "1v",
                diff1mprc: "1 mån",
                diff3mprc: "3 mån",
                diffytdprc: "i år",
                diff1yprc: "1 år",
                diff5yprc: "5 år",
                diff10yprc: "10 år",
            },
            individualdiffcolor: true,
        };
        return {
            detailedInstrument,
        };
    },
};
</script>
<style lang="scss">
@import "@/assets/scss/variables.scss";
#history-div {
    .box-container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    table {
        white-space: nowrap;
        width: 100%;
        border-collapse: collapse;
        thead {
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 14px;
            color: rgba(37, 45, 64, 0.5);
            tr {
                padding: 0 0.5rem;
                th {                    
                    &:first-child {
                        padding-left: 0.5rem;                        
                    }
                    &:last-child {
                        padding-right: 0.5rem;
                    }
                }
            }
        }
        tbody {
            tr {
                border-bottom: thin solid rgb(0 0 0 / 12%);
                font-style: normal;
                font-weight: 600;
                font-size: 13px;
                line-height: 16px;
                color: #252d40;
                td {
                    padding: 12px 0;
                    &:first-child {
                        padding-left: 0.5rem;
                        border-radius: 4px 0 0 4px;
                    }
                    &:last-child {
                        padding-right: 0.5rem;
                        border-radius: 0 4px 4px 0;
                    }
                }
            }
        }
    }
}
@media (max-width: 781px) {
    #history-div {
        .box-container {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            padding: 0;
            padding-top: 1rem;
        }
        .box-header {
            padding-left: 0.5rem;
        }
        #history-list {
            position: relative;
            overflow: auto;
            &::-webkit-scrollbar {
                display: none;
            }
            table {
                thead {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 12px;
                    line-height: 14px;
                    color: rgba(37, 45, 64, 0.5);
                    tr {
                        th {
                            &:not(:first-child) {
                                padding-left: 13px;
                            }
                        }
                    }
                }
                tbody {
                    tr {
                        &:not(.active) {
                            .millistream-name {
                                background-color: #fffcf8;
                            }
                        }
                        &:last-child {
                            border-bottom: none;
                        }
                        td {
                            &:first-child {
                                border-radius: 0;
                            }
                            &:last-child {
                                border-radius: 0;
                            }
                            &:not(:first-child) {
                                padding-left: 13px;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>


